import {API} from "aws-amplify";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from 'react';
import TableRow from "@material-ui/core/TableRow";
import {useStyles} from "./TopicTableKardexStyles";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import TableContainer from "@material-ui/core/TableContainer";
import {verifyPayments} from "../../CommonUtilities/UtilityFunctions";

export default function TopicTableKardex(props) {
    const classes = useStyles();
    const [grades, setGrades] = useState([]);
    const [topicHasBeenGraded, setTopicHasBeenGraded] = useState(true);

    useEffect(() => {
        let tmp = props.topicHasBeenGraded ? props.topicHasBeenGraded : false;
        setTopicHasBeenGraded(tmp);
        if (props.grades) {
            const temp = props.grades.map(o => {
                return {
                    ...o,
                    type: 'grade'
                };
            });
            setGrades(temp);
        }
    }, [props.grades, props.topicId, props.groupId, props.topicHasBeenGraded]);

    const handleChange = (nameValue, index) => ({target: {value}}) => {
        const type = grades[index].type;
        let updatedGrade, final;
        updatedGrade = {
            desempeno: parseFloat(grades[index].grade.desempeno || 0),
            actitud: parseFloat(grades[index].grade.actitud || 0),
            producto: parseFloat(grades[index].grade.producto || 0),
            conocimiento: parseFloat(grades[index].grade.conocimiento || 0),
        };
        updatedGrade[nameValue] = parseFloat(value);
        final = updatedGrade.desempeno + updatedGrade.actitud + updatedGrade.producto + updatedGrade.conocimiento;

        if (final > 10) {
            alert('La calificación final no puede ser mayor a 10');
            return;
        } else {
            const typeGrade = type === 'extra' ? 'extraordinario' : 'grade';
            setGrades([
                ...grades.slice(0, index),
                {
                    ...grades[index],
                    grade: {
                        ...grades[index].grade,
                        [typeGrade]: `${final}`,
                        [nameValue]: value,
                    },
                }, ...grades.slice(index + 1)
            ]);
        }
    };

    const updateGradesApi = payload => {
        return API.put("misServiciosEscolares", "/grades", {
            body: payload
        });
    };

    function formatGradesForAPI() {
        let gradesPayload = {};
        grades.forEach(grade => {
            gradesPayload[grade.studentUuid] = {
                studentUuid: grade.studentUuid,
                studentId: grade.studentId,
                [props.topicId]: {
                    ...grade.grade,
                    topicId: props.topicId,
                },
            };
        });
        return gradesPayload;
    }

    const updateGrades = async () => {
        props.callback(true);
        const payload = {
            topicId: props.topicId,
            groupId: props.groupId,
            grades: formatGradesForAPI(),
        };
        try {
            await updateGradesApi(payload);
            props.callback(false, false);
        } catch (e) {
            props.callback(false, true);
        }
    };

    const TableHeader = () => {
        return (
            <TableHead>
                <TableRow className={classes.tableHeaders}>
                    <TableCell>Matrícula</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Desemp</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Actitud</TableCell>
                    <TableCell>Conocim</TableCell>
                    <TableCell>C.Final</TableCell>
                    <TableCell>Status</TableCell>
                </TableRow>
            </TableHead>
        );
    };

    return (
        <div>
            <Button
                color='secondary'
                variant='contained'
                onClick={updateGrades}
                // disabled={topicHasBeenGraded}
            >
                Guardar cambios
            </Button>
            <TableContainer component={Paper} className={classes.container}>
                <Table className={classes.table} aria-label="simple dense table">
                    <TableHeader/>
                    <TableBody>
                        {grades
                            ? grades.map((row, index) => (
                                <TableRow
                                    key={index}
                                    style={{backgroundColor: verifyPayments(row.status) ? '#F08080' : 'clear'}}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.studentId}
                                    </TableCell>
                                    <TableCell>
                                        {row.name}
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={row.type === 'extra' ? row.grade.desempenoExtra : row.grade.desempeno}
                                            type='number'
                                            step={0.1}
                                            onChange={handleChange(row.type === 'extra' ? 'desempenoExtra' : 'desempeno', index)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={row.type === 'extra' ? row.grade.productoExtra : row.grade.producto}
                                            type='number'
                                            onChange={handleChange(row.type === 'extra' ? 'productoExtra' : 'producto', index)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={row.type === 'extra' ? row.grade.actitudExtra : row.grade.actitud}
                                            type='number'
                                            onChange={handleChange(row.type === 'extra' ? 'actitudExtra' : 'actitud', index)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={row.type === 'extra' ? row.grade.conocimientoExtra : row.grade.conocimiento}
                                            type='number'
                                            onChange={handleChange(row.type === 'extra' ? 'conocimientoExtra' : 'conocimiento', index)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={row.grade.grade}
                                            type='number'
                                            onChange={handleChange('grade', index)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {row.status}
                                    </TableCell>
                                </TableRow>
                            )) : <>
                            </>}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};